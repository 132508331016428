
define('include/requirejs-text/text!modules/Categories/views/Category_Item_View.html',[],function () { return '<script type="text/x-underscore" id=\'category-template\'>\n    <a href="#" id="<%=model.get(\'name\')%>" data-i18n="[title]Room.tooltip.<%=model.get(\'name\')%>;Room.text.<%=model.get(\'name\')%>"/>\n</script>\n';});

define('include/require-css/css!modules/Categories/views/Category_Item_View',[],function(){});
/*
 * @Copyright (C) 2016-01-03 by Yaggee
 *
 * https://www.yaggee.com
 * 
 * Yaggee es una plataforma de video stream, webrtc, socket.oi, odoo, requirejs ....
 * La meta es generar un video chat, para que cada persona pueda transmitir lo que desee
 * en el momento que lo desee.
 */
 
define('modules/Categories/views/Category_Item_View',[
    'jquery',
    'underscore',
    'backbone',
    'include/requirejs-text/text!./Category_Item_View.html',
    'include/require-css/css!./Category_Item_View.css'
], function($, _, Backbone, Template) {
    
    /**
     * @class Category_Item_View
     *
     * Backbone.View
     * Class para ver una categoria de la plataforma
     */
    var Category_Item_View = Backbone.View.extend({
        TAG: 'Category_Item_View',
        model: null,
        tagName: 'li',
        template: null,
        appmodel: null,
        
        /**
         * Category_Item_View::wrapper
         * Optiones para graficar la vista
         */
        wrapper: {
            html    : Template,
            name    : '#category-template'
        },
        
        /**
         * Category_Item_View.events
         * Objeto de los eventos para la vista
         */
        events:  {
            'click'     : 'load',
        },
        
        /**
         * Category_Item_View.initialize
         * Constructor del widget de configuracion de la publicidad del usuario
         * @param <Object> atributes - Atributos de la vista
         * @param <AppModel> appmodel - Modulo del aplicativo
         */
        initialize: function(atributes, appmodel) {
            var self = this;
            void 0;
            self.appmodel = appmodel;
            self.template = self.appmodel.getTemplate(self.wrapper);
            self.model.on('select', self.onSelect, self);
            self.model.on('unselect', self.onUnselect, self);
            void 0;
        },
        
        /**
         * Category_Item_View.render
         * Renderiza el modulo
         */
        render: function() {
            var self = this;
            void 0;
            self.$el.html(self.template(self));
            self.$('[data-i18n]').i18n();
            self.$('[data-i18n]').tooltip({placement : 'right'});
            if(self.model.get('select')) {
                self.$el.addClass('category_active');
                // @Note - Fuerza el sistema a selecionar graficamente uno
                self.model.trigger('select', self.model);
            }
            void 0;
        },
        
        /**
         * Category_Item_View.load
         * Carga el thema de la categoria
         */
        load: function() {
            var self = this;
            void 0;
            self.appmodel.getModule('Search', function(search) {
                $('#Shelf').tab('show');
                self.model.load();
                search.clear();
                search.find();
            });
            void 0;
        },
        
        /**
         * Category_Item_View.onSelect
         * Seleciona la lista probocando la cargade este
         * @private
         */
        onSelect: function(category) {
            var self = this;
            void 0;
            self.$el.addClass('category_active');               
            void 0;
        },
        
        /**
         * Category_Item_View.onUnselect
         * Envia la vista como un/select
         * @private
         */
        onUnselect: function(category) {
            var self = this;
            void 0;
            self.$el.removeClass('category_active');
            void 0;
        },
        
    });
    
    return Category_Item_View;
    
});

/*
 * @Copyright (C) 2016-01-03 by Yaggee
 *
 * https://www.yaggee.com
 * 
 * Yaggee es una plataforma de video stream, webrtc, socket.oi, odoo, requirejs ....
 * La meta es generar un video chat, para que cada persona pueda transmitir lo que desee
 * en el momento que lo desee.
 */
 
define('modules/Categories/models/Category_Record_Model',[
    'jquery',
    'underscore',
    'backbone'
], function($, _, Backbone) {

    /**
     * @class Category_Record_Model
     *
     * Backbone.Model
     * Modelo para manejar todo los procesos de una categoria
     */
    
    var Category_Record_Model = Backbone.Model.extend({
        appmodel: null,
        
        /**
         * Category_Record_Model::defaults
         * Objeto de parametros por defecto
         */
        
        defaults: {
            id      : 0,
            name    : '',
            select  : false,
        },
        
        /**
         * Category_Record_Model.url
         * Url en de trabajo de las categorias
         * @return <String> url - string de trabajo
         */ 
        
        url: function() {
            var self = this;
            return self.appmodel.url()+'/categories/'+self.id;
        },
        
        /**
         * Category_Record_Model.initialize
         * Constructor del modelo para GeoPosition
         * @param <Object> atributes - Atributos de la vista
         * @param <Object> options - {appmodel: App_Base_Model}
         */
         
        initialize: function(attributes, options) {
            var self = this;
            self.appmodel = options.appmodel;
        },
        
        /**
         * Category_Record_Model.load
         * Carga la categoria en la plataforma
         */
        
        load: function() {
            var self = this;
            if(!self.get('select')) {
                self.set({'select': true});
                var theme = self.get('name');
                if($('link[title='+theme+']').size()===0) {
                    var cssLink = $("<link rel='alternate stylesheet' type='text/css' href='resources/themes/"+theme+".css' title='"+theme+"'>");
                    $('head').append(cssLink);
                }
                for(i=0; (a=document.getElementsByTagName('link')[i]); i++) {
                    if(a.getAttribute('rel').indexOf('style')!==-1 && a.getAttribute('title')) {
                        a.disabled = true;
                        if(a.getAttribute('title')===theme) a.disabled = false;
                    }
                }
            }
        }
        
    });
    
    return Category_Record_Model;
    
});

/*
 * @Copyright (C) 2016-01-03 by Yaggee
 *
 * https://www.yaggee.com
 * 
 * Yaggee es una plataforma de video stream, webrtc, socket.oi, odoo, requirejs ....
 * La meta es generar un video chat, para que cada persona pueda transmitir lo que desee
 * en el momento que lo desee.
 */
 
define('modules/Categories/collections/Categories_Models_Collection',[
    'jquery',
    'underscore',
    'backbone',
    '../models/Category_Record_Model'
], function($, _, Backbone, Category_Record_Model) {

    /**
     * @class Categories_Models_Collection
     *
     * Backbone.Collection
     * Colleccion de todas las categorias de la plataforma
     */
     
    var Categories_Models_Collection = Backbone.Collection.extend({
        TAG: 'Categories_Models_Collection',
        model: Category_Record_Model,
        appmodel: null,
        
        /**
         * Categories_Models_Collection.url
         * Url en el servidor para colleccion de categorias
         * @return <String> url - ruta en el servidor
         */
        
        url: function() {
            var self = this;
            return self.appmodel.url()+'/categories';
        },
        
        /**
         * Categories_Models_Collection.initialize
         * Constructor de la collecion de Streams
         * @param <Object> atributes - Atributos de la vista
         * @param <Object> options - debe contener appmodel
         */
        
        initialize: function(attributes, options) {
            var self = this;
            void 0;
            self.appmodel= options.appmodel;
            self.on('change:select', self.onSelect, self);
            void 0;
        },
        
        /**
         * Categories_Models_Collection.onSelect
         * evento cuando se cambia el actual modelo
         * @param <Category_Record_Model> category - El modelo actualmente seleccionado
         * @private
         */
         
        onSelect: function(category) {
            var self = this;
            void 0;
            var selects = self.where({'select': true});
            _.each(selects, function(model) {
                if(category.id!==model.id) {
                    model.set({'select': false}, {silent: true});
                    model.trigger('unselect', model);
                }
            });
            category.trigger('select', category);
            void 0;
        },
        
        /**
         * Categories_Models_Collection.getModel
         * Obtiene un Category_Record_Model
         * @param <Object> properties - Atributos para ser buscados en la collecion
         * @param <function> callback - Funcion para ser llamada despues de la busqueda
         */
         
        getModel: function(properties, callback) {
            var self = this;
            void 0;
            var _model_ = self.findWhere(properties);
            if(!_.isUndefined(_model_)) {
                callback(null, _model_);
            } else {
                callback(true, null);
            }
            void 0;
        },
        
    });
    
    return Categories_Models_Collection;

});


define('include/requirejs-text/text!modules/Categories/views/Categories_Index_View.html',[],function () { return '<script type="text/x-underscore" id=\'categories-template\'>\n<div class="categories-content">\n    <div class="categories-header">\n        <img class="categories_image" alt="Yaggee Inc" src="images/logo.webp">\n        <span class="fa fa-angle-down fa-2x arrow"/>\n        <span class="fa fa-commenting fa-2x hidden"/>\n    </div>\n    <div class="categories-body" style="display: none;">\n        <ul>\n            <!-- CategoryViews -->\n        </ul>\n    </div>\n</div>\n<div class="mobile_content">\n    <span class="fa fa-bars fa-2x" data-action="mobile" data-i18n="[title]Actions.tooltip.mobile"/>\n</div>\n</script>\n';});

define('include/require-css/css!modules/Categories/views/Categories_Index_View',[],function(){});
/*
 * @Copyright (C) 2016-01-03 by Yaggee
 *
 * https://www.yaggee.com
 * 
 * Yaggee es una plataforma de video stream, webrtc, socket.oi, odoo, requirejs ....
 * La meta es generar un video chat, para que cada persona pueda transmitir lo que desee
 * en el momento que lo desee.
 */
 
define('modules/Categories/views/Categories_Index_View',[
    'jquery',
    'underscore',
    'backbone',
    './Category_Item_View',
    '../collections/Categories_Models_Collection',
    'include/requirejs-text/text!./Categories_Index_View.html',
    'include/require-css/css!./Categories_Index_View.css'
], function(
        $,
        _,
        Backbone,
        Category_Item_View,
        Categories_Models_Collection,
        Template
    ) {

    /**
     * @class Categories_Index_View
     *
     * Backbone.View
     * Carga un ventana para seleccionar la categoria en que se buscara los peer
     */
    
    var Categories_Index_View = Backbone.View.extend({
        TAG: 'Categories_Index_View',
        el: '.categories_wrapper',
        categories: [],
        appmodel: null,
        template: null,
        categorymodels: null,
        
        /**
         * Categories_Index_View::events
         * Objeto de los eventos para la vista
         */
         
        events: {
            'mouseenter'            : 'show',
            'mouseleave'            : 'hide',
        },
        
        /**
         * Categories_Index_View.initialize
         * Constructor del widget de categorias para filtrar peer
         * @param <Object> attributes - Atributos de la vista
         * @param <App_Record_Model> appmodel - Modelo de la aplicacion
         */
         
        initialize: function(attributes, appmodel) {
            var self = this;
            void 0;
            self.appmodel = appmodel;
            self.$el.html(Template);
            var template = self.$("#categories-template").html();
            self.template = _.template(template);
            self.categorymodels = new Categories_Models_Collection(null, {
                appmodel: self.appmodel
            });
            self.categorymodels.on('add', self.onAdd, self);
            self.categorymodels.fetch({appmodel: self.appmodel});
            void 0;
        },
        
        /**
         * Categories_Index_View.render
         * Renderiza el modulo
         */
         
        render: function() {
            var self = this;
            void 0;
            self.$el.html(self.template(self));
            self.$('[data-i18n]').i18n();
            self.$('[data-i18n]').tooltip({placement : 'bottom'});
            void 0;
        },
        
        /**
         * Categories_Index_View.onAdd
         * Suma una categoria a la vista
         * @params <Category_Record_Model> model - categoria definida en el sistema
         * @access Private
         */
         
        onAdd: function(model) {
            var self = this;
            void 0;
            var category = new Category_Item_View({model:model}, self.appmodel);
            category.render();
            self.categories.push(category);
            self.$('.categories-body ul').append(category.el);
            void 0;
        },
        
        /**
         * Categories_Index_View.show
         * Presenta la ventana de categoria
         */
         
        show: function() {
            var self = this;
            void 0;
            self.$('.categories-content').addClass('select');
            self.$('.arrow').addClass('fa-angle-up').removeClass('fa-angle-down');
            self.$('.categories-body').show();
            void 0;
        },
        
        /**
         * Categories_Index_View.hide
         * Esconde la ventana de categorias
         */
         
        hide: function() {
            var self = this;
            void 0;
            self.$('.categories-content').removeClass('select');
            self.$('.arrow').addClass('fa-angle-down').removeClass('fa-angle-up');
            self.$('.categories-body').hide();
            void 0;
        },
        
    });
  
    return Categories_Index_View;
    
});

/*
 * @Copyright (C) 2016-01-03 by Yaggee
 *
 * https://www.yaggee.com
 * 
 * Yaggee es una plataforma de video stream, webrtc, socket.oi, odoo, requirejs ....
 * La meta es generar un video chat, para que cada persona pueda transmitir lo que desee
 * en el momento que lo desee.
 */

define('modules/Categories/__init__',[
    'jquery',
    'underscore',
    'backbone',
    './views/Categories_Index_View'
], function($, _, Backbone, Categories_Index_View) {
    
    return Categories_Index_View;
    
});

(function(c){var d=document,a='appendChild',i='styleSheet',s=d.createElement('style');s.type='text/css';d.getElementsByTagName('head')[0][a](s);s[i]?s[i].cssText=c:s[a](d.createTextNode(c));})
('.categories_wrapper {\n    width: 20%;\n    height: 100%;\n    font-family: Arial,sans-serif;\n    font-size: 12px;\n    font-weight: normal;\n    text-align: center;\n}\n\n.categories_wrapper .select {\n    border-radius: 5px 5px 0px 0px;\n    background-color: rgba(0,0,0,0.9);\n}\n\n.categories_wrapper .tooltip-inner {\n    min-width: 180px;\n}\n\n.categories_wrapper .categories-content {\n    width: 100%;\n    height: 100%;\n    margin-left: auto;\n    margin-right: auto;\n    position: relative;\n}\n\n.categories-content .categories-header {\n    width: 100%;\n    padding: 7px 0;\n}\n\n.categories-content .categories-header .categories_image {\n    float: left;\n    width: 180px;\n    height: 37px;\n}\n\n.categories-content .categories-header .arrow {\n    padding-top: 5px;\n}\n\n.categories-content .categories-header .fa-html5 {\n    display: none;\n}\n\n.categories-content .categories-body {\n    position: absolute;\n    top: 49px;\n    width: 100%;\n    z-index: 4;\n}\n\n.categories-content .categories-body  ul {\n    background-color: rgba(0,0,0,0.9);\n    border-radius: 0px 0px 5px 5px;\n    list-style: none outside none;\n    margin: 0px;\n    padding: 0px 5px 5px 5px;\n    width: 100%;\n}\n\n.categories-body ul a {\n    border-bottom: 1px dotted rgb(81, 81, 81);\n    display: block;\n    outline: medium none;\n    padding: 7px 0px 7px 3px;\n    text-decoration: none;\n    color: #dddddd;\n}\n\n.categories-body ul .category_active a {\n    color: #ffb400;\n    background-color: #000;\n}\n\n/* Phone Style*/\n@media only screen and (hover: none) and (pointer: coarse) {\n\n    .categories_wrapper {\n        font-size: 15px;\n    }\n\n    .categories_wrapper .categories-content {\n        display: none;\n    }\n\n}\n/* Phone Style: end*/\n');
